<template>
  <div class="flex items-center">
    {{ montantCommande }}
  </div>
</template>

<script>
export default {
  name: 'CellRendererMontantCommande',
  props: ['details'],
  computed: {
    montantCommande () {
      /* Somme des lignes du detail commande */
      const total = this.details.reduce(function (accumulator, detail) {
        return accumulator + (detail.PrixAchatBrut * detail.Qte)
      }, 0)
      console.log(`Montant BR: ${total}`)
      /* Formatage du calcul */
      const val = (total / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }
  }
}
</script>
